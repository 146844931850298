<template>
  <router-view />
</template>

<script>
import { toggleBodyTheme, getQueryParameters } from 'HELPERS'
import localforage from 'LF'

const PROJECT = process.env?.VUE_APP_PROJECT

export default {
  computed: {
    isProfileDeprecated() {
      return this.getActualProfileVersion !== this.getProfileVersion
    },
    getProfileVersion() {
      return this.$store.getters.getProfileVersion
    },
    getActualProfileVersion() {
      return this.$store.state.profileVersion
    }
  },
  async created() {
    this.$store.commit('SYSTEM_SET', ['env', Object.assign({}, process.env)])

    this.configureAvailableRoutes()

    const systemConfig = await localforage.getItem('systemConfig')
    const config = JSON.parse(systemConfig)
    const theme = config?.theme || 'system'

    this.$store.commit('SYSTEM_SET', ['theme', theme])
    this.$store.commit('SYSTEM_SET', ['locale', config?.locale || 'ru'])
    this.$store.commit('SYSTEM_SET', ['abbr', !!config?.abbr])
    await toggleBodyTheme(theme)

    if (!getQueryParameters()?.from_profile_notify) {
      setTimeout(() => {
        if (this.isProfileDeprecated) {
          this.$notify({
            title: 'Профиль устарел',
            message:
              'Некоторый функционал может работать некорректно. Пожалуйста, создайте новый.',
            link: { module: 'profile', query: { from_profile_notify: 1 } },
            duration: 8000
          })
        }
      }, 2048)
    }
  },
  beforeCreate() {
    this.$store.commit('SYSTEM_SET', ['notification', null])
  },
  methods: {
    configureAvailableRoutes() {
      const appRoute = this.$router.options.routes.find(
        route => route.name === 'app'
      )

      const allRouteNames = this.getAllRouteNames(appRoute)

      this.$store.commit('SYSTEM_SET', [
        'availableRoutes',
        new Set(allRouteNames)
      ])
    },
    getAllRouteNames(route) {
      let routeNames = []

      if (route.children && route.children.length > 0) {
        route.children.forEach(module => {
          const moduleProjects = module.meta?.projects

          const isModuleInProject =
            !Array.isArray(moduleProjects) || moduleProjects.includes(PROJECT)

          if (isModuleInProject) {
            routeNames.push(module.name)
          }

          if (
            module.children &&
            module.children.length > 0 &&
            isModuleInProject
          ) {
            const nestedRouteNames = this.getAllRouteNames(module)
            routeNames = routeNames.concat(nestedRouteNames)
          }
        })
      }

      return routeNames
    }
  }
}
</script>
