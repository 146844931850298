import rDate from 'ritm-date'
import $store from 'STORE'

export const objectToQueryString = (obj = {}) => {
  const res = new URLSearchParams()
  for (const param in obj) {
    obj[param] !== null &&
      res.append(
        param,
        typeof obj[param] === 'object'
          ? JSON.stringify(obj[param])
          : String(obj[param])
      )
  }

  return res
}

export const getUserFullName = user => {
  if (!user) return '—'
  let last, first
  const { surname, name, username } = user

  if (surname) {
    last = capitalizeFirst(surname)
  }

  if (name) {
    first = capitalizeFirst(name)
  }

  return last && first
    ? `${last} ${first}`
    : !last && !first
    ? username
    : first || last
}

export const isNumber = n => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const typeIsNumber = type => {
  const types = {
    number: true,
    integer: true,
    numeric: true,
    decimal: true
  }

  return Object.hasOwn(types, type)
}

export const typeIsSimple = type => {
  return (
    typeIsNumber(type) || ['boolean', 'string'].includes(type.toLowerCase())
  )
}

export const dataURLtoBlob = dataurl => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new Blob([u8arr], { type: mime })
}

export const saveBlobAsFile = (blob, name) => {
  const a = document.createElement('a')
  const url = URL.createObjectURL(blob)

  a.href = url
  a.download = name

  document.body.appendChild(a)
  a.click()

  setTimeout(function () {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 0)
}

export const capitalizeFirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getValueByType = (value, type = 'default', k, lvl = 0) => {
  const level = 1 + lvl
  const locale = $store.getters.getLocale

  if (level > 2) return '...'

  const isObject = obj => {
    return Object.prototype.toString.call(obj) === '[object Object]'
  }

  if (value === null || value === undefined) {
    return '— '
  } else if (Array.isArray(value)) {
    return value.length > 1
      ? value.map(v => getValueByType(v, type)).join(', ')
      : getValueByType(value[0], type) || '— '
  } else if (isObject(value) && type !== 'alias') {
    const objectKeys = Object.keys(value)

    if (!objectKeys.length) {
      return '— '
    }

    return objectKeys
      .map(key => {
        if (key === 'geom' || key === 'geometry') {
          return 'geometry object'
        }

        return `${k ? '∟ ' + key : key}: ${
          isObject(value[key]) && !!Object.keys(value[key]).length
            ? '\n' + getValueByType(value[key], type, key, level)
            : getValueByType(value[key], type) + ';'
        }`
      })
      .join('\n')
  } else if (typeof value === 'boolean') {
    // return i18n.t(value ? 'yes' : 'no') // TODO: upgrade to i18n
    return value ? 'Да' : 'Нет'
  } else if (type === 'alias') {
    return value[locale] || '— '
  } else if (type === 'date') {
    return rDate(value).format('DD.MM.YYYY')
  } else if (type === 'datetime' || type === 'timestamp') {
    return rDate(value).format()
  } else if (type === 'time') {
    if (rDate(value).date == 'Invalid Date') {
      return value.split('+')[0]
    } else {
      rDate(value).format('HH:mm')
    }
  } else {
    return value || isNumber(value) ? String(value) : '—'
  }
}

export const getDegree = number => {
  const digits = {
    '-': '\u{207B}',
    0: '\u{2070}',
    1: '\u{00B9}',
    2: '\u{00B2}',
    3: '\u{00B3}',
    4: '\u{2074}',
    5: '\u{2075}',
    6: '\u{2076}',
    7: '\u{2077}',
    8: '\u{2078}',
    9: '\u{2079}'
  }

  let degree = ''

  for (let n of String(number)) {
    degree += digits[n]
  }

  return degree
}

export const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const isHoverCondition = condition => {
  return (
    condition &&
    Array.isArray(condition) &&
    condition.flat(2).includes('feature-state')
  )
}

export const minMaxNotANull = ({ min, max }) => {
  return Number(min) !== Number(max)
}

export const minMaxNotAUndefined = ({ min, max }) => {
  return min !== undefined && max !== undefined
}

export const parseFields = ({ properties, options, module }) => {
  const storageRelations = sessionStorage.getItem(`${module}_relations`)
  const relations = storageRelations ? JSON.parse(storageRelations) : {}
  const locale = $store.getters.getLocale
  const id = options.fieldsType
  const allFields = {
    ...$store.state[module].fields[id],
    ...options.customFields
  }
  const fields = []

  const getTitle = key => {
    if (!id) return options.titles?.[key] || key

    return typeof allFields[key] === 'object' || !allFields[key]
      ? allFields[key]?.alias?.[locale] || key
      : allFields[key]
  }

  for (const key in properties) {
    if (
      ((options.systemFields && !options.systemFields.includes(key)) ||
        options.displayProperties?.includes(key)) &&
      allFields[key]?.type !== 'geometry' &&
      allFields[key]?.type !== 'json'
    ) {
      let value

      const currRelations = relations[id]
        ? relations[id]?.[key]
        : relations?.[key]

      if (currRelations) {
        const relation = Array.isArray(currRelations)
          ? currRelations.find(x => x.id === properties[key])
          : currRelations[properties[key]]

        const nameField = allFields[key].relation?.alias_key

        value = relation?.alias?.[locale] || relation?.[nameField]
      } else if (options?.setFieldValue) {
        value = options.setFieldValue(key)
      } else {
        value = properties[key] === 'null' ? null : properties[key]
      }

      if (allFields[key]?.type === 'datetime' && value) {
        value = rDate(value).format('DD.MM.YY HH:mm')
      }

      fields.push({
        title: getTitle(key),
        value
      })
    }
  }

  return fields
}

export const parseToPopup = ({ properties, options, module }) => {
  const fields = parseFields({ properties, options, module })

  const titleField =
    properties[options.titleField] !== 'null'
      ? properties[options.titleField]
      : ''

  const title = `${options.title || ''}${
    options.titleField?.length > 0 ? titleField : ''
  }`

  return {
    id: properties.id,
    icon: options.icon,
    title,
    caption: options.caption
      ? properties[options.caption]
      : `ID: ${properties.id}`,
    fields
  }
}

export const getCountWord = (value, count) => {
  if ($store.getters.getLocale !== 'ru') return value

  let res
  const mod = count % 10

  if (mod === 1 && count !== 11) {
    res = ''
  } else if (mod >= 2 && mod <= 4 && (count < 12 || count > 14)) {
    res = 'а'
  } else res = 'ов'

  return `${value}${res}`
}

// TODO: replace with i18n
export const getRegistryTitle = ({
  activeNode,
  loadingNode,
  loading,
  data,
  limit,
  total,
  defaultTitle
}) => {
  const isEn = $store.getters.getLocale === 'en'
  const noObjectsTitle =
    defaultTitle || (isEn ? 'Data registry' : 'Реестр данных')

  if (!activeNode && !loadingNode) return noObjectsTitle

  const objectName = isEn ? 'objects' : 'объект'
  const fromName = isEn ? 'from' : 'из'

  let text

  if (loading) {
    text = '—'
  } else if (limit >= total) {
    const count = total || data?.length || 0
    text = `${count} ${getCountWord(objectName, count)}`
  } else {
    text = `${data?.length} ${getCountWord(
      objectName,
      data?.length
    )} ${fromName} ${total}`
  }

  return `${loadingNode?.name || activeNode?.name} | ${text}`
}

export const getQueryParameters = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const queryParams = {}

  for (const [key, value] of urlParams) {
    queryParams[key] = value
  }

  return queryParams
}

export const getModelKey = () => {
  const query = getQueryParameters()
  const modelId = query.mainId || query.id
  const isScenarioMode = !!query.mainId

  return isScenarioMode ? `scenario_based_on_${modelId}` : `model_${modelId}`
}

export const transliterate = input => {
  const russianLetters = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'yo',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'kh',
    ц: 'ts',
    ч: 'ch',
    ш: 'sh',
    щ: 'sch',
    ъ: '',
    ы: 'y',
    ь: '',
    э: 'e',
    ю: 'yu',
    я: 'ya'
  }

  return input
    .toLowerCase()
    .split('')
    .map(char => (russianLetters[char] ? russianLetters[char] : char))
    .join('')
}

// Create source template when create new object
export const createSourceTemplate = ({
  fields = {},
  excludeFields = [],
  includeFields = []
}) => {
  const source = {}
  const _excludeFields = ['id', ...excludeFields]

  for (const key in fields) {
    const { type } = fields[key]

    if (
      !_excludeFields.includes(key) &&
      !(type === 'json' || type === 'jsonb') &&
      (includeFields.includes(key) || includeFields.length === 0)
    ) {
      let value

      switch (type) {
        case 'string':
          value = ''
          break

        case 'array':
          value = []
          break

        case 'boolean':
          value = false
          break

        default:
          value = null
      }

      source[key] = value
    }
  }

  return source
}

export const dividedNum = n => Number(n).toLocaleString()

export const generateId = () => {
  return (+new Date()).toString(16)
}

export const getActiveLayersGetterName = module => {
  switch (module) {
    case 'gis':
      return 'getGisActiveLayers'
    case 'ksodd':
      return 'getKsoddActiveLayers'
    case 'digitalTwin': {
      const isPt = window.location.href.includes('pt-editor')

      return isPt
        ? 'getDigitalTwinPTActiveLayers'
        : 'getDigitalTwinActiveLayers'
    }

    case 'modeling':
      return 'getModelingActiveLayers'

    default:
      console.warn('unknown module')

      return null
  }
}
